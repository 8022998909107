import React, { useEffect, useState, useRef } from 'react';
import { _dataProvider, IMessage, IUser } from '../dataProvider/DataProvider';
import { getJwtObj, HostOfficialServer, legitHosts } from '../SharedCommon/utils';
import i18n from '../i18n';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { userAvatarName } from '../SharedCommon/UserAvatarName';
import { UserProfileDialog } from '../SharedCommon/UserProfile';

import { GiftedChat } from '../gifted/GiftedChat';
import GTSocket from '../dataProvider/socket';
import voiceIcon from '../img/icon-Voice-input.png';
import defaultAvatar from '../img/icon-DefaultProfilePicture.png';
import deleteIcon from '../img/close.png';
import replyIcon from '../img/icon-reply.png';
import actionIcon from '../img/icon-Search.png';

interface IWebChatProps {
  groupId: number;
  height: string;
  hadNewMessage: boolean;
}

const theAllUserUser = {
  userId: -2,
  name: i18n.t('Chatroom.AllUsers'),
  displayName: i18n.t('Chatroom.AllUsers'),
  loginId: '',
  isGroupLeader: false
};

export const WebChat = ({ height, groupId, hadNewMessage }: IWebChatProps) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [gtSocket, setGtSocket] = useState<GTSocket>();
  const [fromId, setFromId] = useState(-1);
  const [scrollFromId, setScrollFromId] = useState(-1);
  const [inputText, setInputText] = useState('');
  const [showUserSelection, setShowUserSelection] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [mentionedUsers, setMentionedUsers] = useState<IUser[]>([]);
  const giftedChatRef = useRef<GiftedChat>(null);
  const searchText = useRef('');
  const [searchState, setSearchState] = useState<{ inSearch: boolean; searchedMessages: IMessage[] }>({
    inSearch: false,
    searchedMessages: []
  });
  const [showMessageSender, setShowMessageSender] = useState();

  const buttonElement = document.getElementById('idGroupActionButton');
  const buttonHeight = buttonElement ? buttonElement.offsetHeight : 0;
  const chatHeight = `${parseInt(height) - buttonHeight - 10}px`;

  const mapMessage = (data: any) => {
    return data.map((mmm: any) => {
      let text = mmm.message;
      const pa = text.split('!');
      if (text.startsWith('!file!')) {
        //text = pa[pa.length - 1];
        //text = `<a href=${pa[pa.length - 1]}>${mmm.attachment}</a>`;
        text = decodeURIComponent(pa[2]) + '(' + (parseInt(pa[3]) / 1024).toFixed(2) + 'KB)';
      } else if (text.startsWith('!audio!')) {
        const duration = parseInt(pa[2]);
        text = isNaN(duration) ? 'InvalidAudio' : `${(duration / 1000).toFixed(1)}"`;
      }

      const giftedm: any = {
        id: mmm.messageId,
        createdAt: new Date(mmm.time * 1000),
        user: {
          id: mmm.user.id,
          name: _dataProvider.getDisplayNameForUser(mmm.user),
          displayName: mmm.user.displayName,
          loginId: mmm.user.loginId,
          avatar: `${HostOfficialServer}/user/avatar/${mmm.user.id}}`,
          defaultAvatar //GT added: support when the icon url fails
        },
        quote: mmm.quote //GT added: support reply
      };

      if (pa[1] === 'image') {
        giftedm.image = pa[2];
      } else {
        giftedm.text = text;
        if (pa[1] === 'file') {
          //GT added: textStyle -- to support per message styling control
          giftedm.textStyle = { textDecoration: 'underline' };
          if (getJwtObj().userId === mmm.user.id) {
            giftedm.textStyle.textDecorationColor = 'white';
          }
        }
        if (pa[1] === 'audio') {
          giftedm.icon = { image: voiceIcon, style: { width: '20px', height: '20px', verticalAlign: 'middle' } };
        }
        if (pa.length > 1 && mmm.attachment) {
          // GT added: attachment -- forcing the text to be a link to the attachment
          giftedm.attachment = { filename: mmm.attachment, url: pa[pa.length - 1] };
        }
      }
      return giftedm;
    });
  };

  const getMessageTime = (mmm: any) => {
    if (!mmm || !mmm.createdAt) {
      console.log('Invalid message!', mmm);
      return -1;
    }

    return new Date(mmm.createdAt).getTime();
  };

  /*
    const existingMessages = messages;
    const existingMessageLength = existingMessages.length;

    const timestampFromReceivedMessages = this.getMessageTime(newMessages[0]);
    const timestampFromExistingMessages = existingMessageLength > 0 ? this.getMessageTime(existingMessages[0]) : -1;

    const startTimeFromReceivedMessages = this.getMessageTime(newMessages[newMessages.length - 1]);
    const startTimeFromExistingMessages =
      existingMessageLength > 0 ? this.getMessageTime(existingMessages[existingMessageLength - 1]) : -1;
    if (startTimeFromExistingMessages === -1) {
      this.messageFromTime = startTimeFromReceivedMessages;
    } else {
      this.messageFromTime = Math.min(startTimeFromExistingMessages, startTimeFromReceivedMessages);
    }

    // prepend when received message is older, otherwise append newer message
    const prepend = timestampFromExistingMessages > timestampFromReceivedMessages;
    const combinedMessages = prepend
      ? GiftedChat.prepend(existingMessages, newMessages)
      : GiftedChat.append(existingMessages, newMessages);
  */
  const addMessage = (newmessages: any) => {
    const timestampFromReceivedMessages = getMessageTime(newmessages[0]);
    const timestampFromExistingMessages = getMessageTime(messages[0]);
    const timestamp = Math.max(timestampFromExistingMessages, timestampFromReceivedMessages) / 1000;

    _dataProvider.saveUserData(`chat,${groupId}`, { time: timestamp });

    const newtotal = GiftedChat.append(messages, newmessages);
    setMessages(newtotal);
  };

  const onNewMessage = (data: any) => {
    /* setting a global variable via window.___socket prevented 
      double connection, and double new message!!!
    if (!messages || !messages.length) {
      //mystery case: there are two receives, and one of then had messages empty
      //happens in React.StrictMode -- functions are run twice
      //so two sockets are created!, and each push message is received twice
      //but amazingly: for one of the two, the message.length is zero!
      //  there apears to be two copy of the functional component for states/variables
      //  while the rendering is supported only on one of them.
      //  what's the better way of disambiguate the two
      // this doesn't work for a brand new group === needs a better solution
      return;
    }
    */
    console.log('before receiving messages: ', this, messages, data);
    data.forEach((mmm: any) => {
      // the loginId from the incoming message has the user's email address
      // TODO: when server fix this, this is no longer needed
      const msgUser = users.find((uu) => uu.userId === mmm.user.id || uu.id === mmm.user.id);
      //console.log('newMessage find user: ', msgUser);
      mmm.user.loginId = msgUser?.uniqueId ?? msgUser?.loginId;
    });
    const newmessages = mapMessage(data);
    addMessage(newmessages);
    //console.log('after receiving: ', messages);
  };

  const loadMessages = (from: number, forSearch = false) => {
    let queryString = '';
    if (from !== -1) {
      queryString += `&from=${from}`;
    }
    if (forSearch) {
      queryString += `&search=${encodeURIComponent(searchText.current)}`;
    }
    _dataProvider.getGroupMessage(groupId, queryString.substring(1)).then((groupMessages) => {
      if (!groupMessages.length && from === -1) {
        //when initiating a 1:1 chat, the message length starts at 0
        setMessages([]);
        return;
      }
      if (forSearch) {
        const { inSearch, searchedMessages } = searchState;
        if (from === -1) {
          setSearchState({ inSearch, searchedMessages: mapMessage(groupMessages) });
        } else {
          setSearchState({ inSearch, searchedMessages: [...searchedMessages, ...mapMessage(groupMessages)] });
        }
      } else {
        if (from === -1 && hadNewMessage) {
          //the group has newChatMesage
          _dataProvider.saveUserData(`chat,${groupId}`, { time: groupMessages[0].time });
        }
        if (from === -1) {
          setMessages(mapMessage(groupMessages));
        } else {
          setMessages([...messages, ...mapMessage(groupMessages)]);
        }
      }
    });
  };

  const loadUser = () => {
    _dataProvider.getGroupInfo(groupId).then((groupInfo) => {
      setUsers(groupInfo.users);
    });
  };

  useEffect(() => {
    setFromId(-1);
    setScrollFromId(-1);
    setInputText('');
    setMentionedUsers([]);
    loadMessages(-1);
    loadUser();
    setGtSocket(new GTSocket({ id: groupId, onNewMessage }));
    setSearchState({ inSearch: false, searchedMessages: [] });
    //searchText.current = '';
    return () => {
      if (gtSocket) {
        gtSocket.closeSocket();
        setGtSocket(undefined);
      }
    };
  }, [groupId]);

  useEffect(() => {
    if (fromId !== -1) {
      // -1 case is done by the groupId change
      // this effect only respond to fromId change from scolling
      loadMessages(fromId);
    }
  }, [fromId]);

  useEffect(() => {
    if (scrollFromId !== -1) {
      // -1 case is done by the innitial search result
      // this effect only respond to fromId change from scolling
      loadMessages(scrollFromId, true);
    }
  }, [scrollFromId]);

  const onDeleteMessage = (message: any) => {
    setMessages([...messages.filter((msg) => msg.id !== message.messageId)]);
  };

  useEffect(() => {
    console.log('state message changed: ', this, messages);
    if (gtSocket) {
      //this has to be done for React.StrictMode so that
      //the receiver will be on the copy of the functional component
      //that has the right state
      //this, when setup, at the constructor time, had
      //no effect on receiving -- somehow, it remember the empty
      //state at the creation time.
      //gtSocket.setCallback(receiveNewMessage);
      gtSocket.newMessageCallback = onNewMessage;
      gtSocket.deleteMessageCallback = onDeleteMessage;
    }
  }, [messages]);

  const sendMessage = (msgtext: string, replyMsgId = -1) => {
    const text = msgtext.trim();
    if (text.length === 0) {
      return; // no op
    }

    const mentioned = [];
    //needs to deal with theAllUserUser ==
    if (text.indexOf(`@${theAllUserUser.name}`) !== -1) {
      users.forEach((user) => mentioned.push(user.userId));
    } else {
      //user might be deleted in the text editing after selection
      for (const user of mentionedUsers) {
        if (text.indexOf(`@${user.name}`) !== -1) {
          mentioned.push(user.userId);
        }
      }
    }
    console.log(mentioned, mentionedUsers);

    _dataProvider.postGroupMessage(groupId, text, replyMsgId, mentioned).then((success) => {
      if (success) {
        //socket connection will receive the new message
        //addMessage(newmessages);
      } else {
        alert('send failed, network might be down...');
      }
    });
  };

  const onSend = (newmessages: any) => {
    const { inSearch } = searchState;
    if (inSearch) {
      setSearchState({ inSearch: false, searchedMessages: [] });
      return;
    }
    console.log('onSend: ', newmessages);
    sendMessage(newmessages[0].text);
  };

  const isNearTop = (layoutHeight: number, contentOffset: number, contentHeight: number) => {
    const topPadding = 80;

    // content height and layout measurement values are float. The comparison can return unexpected result if they have different precision level.
    // Convert them to int to ensure the same precision level to compare.
    const contentH = Math.round(contentHeight);
    const layoutH = Math.round(layoutHeight);

    if (contentH <= layoutH) {
      return false;
    }

    //console.log('Webchat scroll', contentH, layoutH, contentOffset);

    const result = contentH - layoutH - topPadding <= Math.abs(Math.round(contentOffset));
    return result;
  };
  /*
      this.messageFromTime = Number.MAX_VALUE;

  */
  const loadEarlierMessagesAsync = (forSearch = false) => {
    if (messages.length === 0) {
      return;
    }
    if (forSearch) {
      const { searchedMessages } = searchState;
      if (scrollFromId === searchedMessages[searchedMessages.length - 1].id) {
        console.log(`Already called getMessages for ${scrollFromId}, skipping`);
        return;
      }

      //this will trigger an load of early messages
      setScrollFromId(searchedMessages[searchedMessages.length - 1].id);
    } else {
      if (fromId === messages[messages.length - 1].id) {
        console.log(`Already called getMessages for ${fromId}, skipping`);
        return;
      }

      //this will trigger an load of early messages
      setFromId(messages[messages.length - 1].id);
    }
    /*
    const data = { from: fromId, search: searchText };
    if (this.shareInGroups) {
      data.shareInGroups = this.shareInGroups;
    } else {
      data.tag = currentTag;
    }
    await this.chatServer.getMessagesAsync(data);
    */
  };

  const onInputTextChanged = (text: string) => {
    if (searchState.inSearch) {
      //no input?
      return;
    }
    setInputText(text);
    if (text[text.length - 1] === '@') {
      //TODO: this doesn't work if the user type @ in the middle of the text
      // to solve this fully, we need to render our own input<>, and then
      // capture the keydown event == like the mobile app did
      setShowUserSelection(true);
    }
  };

  const handleScroll = (e: any) => {
    if (isNearTop(e.target.clientHeight, e.target.scrollTop, e.target.scrollHeight)) {
      loadEarlierMessagesAsync(searchState.inSearch);
    }
  };

  const renderUserInSelection = (user: IUser) => {
    user.tag = _dataProvider.getMyTagForUser(user.id || user.userId);
    return (
      <div
        key={user.userId}
        className='avatar-wrapper'
        onClick={() => {
          setShowUserSelection(false);
          if (user.userId === -2) {
            //this is theAllUserUser
            //this set is not needed, because onSend will rely on the text
            //setMentionedUsers(users);
          } else if (mentionedUsers.indexOf(user) === -1) {
            setMentionedUsers([user, ...mentionedUsers]);
          }
          setInputText(inputText + user.name + ' ');
          giftedChatRef?.current?.focusTextInput();
        }}>
        {userAvatarName(user, 3, false, '40px', 10)}
      </div>
    );
  };

  const deleteMsg = async (msg: any) => {
    const userConfirmed = confirm(i18n.t('Chatroom.ConfirmDelete'));
    if (userConfirmed) {
      const success = await _dataProvider.deleteChatMessage(groupId, msg.id);
      if (success) {
        // need to refresh, but there is delete notification coming??
      }
    }
  };

  const replyMsg = (msg: any) => {
    const replyText = prompt(i18n.t('Chatroom.ReplyPrompt'));
    if (replyText) {
      sendMessage(replyText, msg.id);
    }
  };

  const messageActionSheet = (msg: any) => {
    const actions = [{ label: i18n.t('Chatroom.Reply'), icon: replyIcon, action: replyMsg }];

    let allowDelete = getJwtObj().userId === msg.user.id;
    if (!allowDelete) {
      const uuu = users.find((uu) => getJwtObj().userId === uu.userId);
      allowDelete = !!uuu && uuu.isGroupLeader;
    }

    if (allowDelete) {
      actions.push({ label: i18n.t('authoring.Delete'), icon: deleteIcon, action: deleteMsg });
    }
    return actions;
  };

  const onPress = (msg: any) => {
    if (msg.attachment) {
      const url = msg.attachment.url;
      if (!url) {
        return;
      }
      if (msg.attachment.filename.indexOf('.docx') === msg.attachment.filename.length - 5) {
        const remoteFile = _dataProvider.getFileUrlInChat(groupId, msg.id);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = remoteFile;
        document.body.appendChild(iframe);
      } else {
        window.open(url);
      }
    }
  };

  const onUrlPress = async (url: string) => {
    try {
      const urlObj = new URL(url);

      //take the last two part of the hostname
      const rootDomain = urlObj.hostname.split('.').slice(-2).join('.');
      if (legitHosts.includes(rootDomain)) {
        if (!urlObj.pathname.startsWith('/launch')) {
          return;
        }
        const params = urlObj.searchParams;
        const code = params.get('code') || '';
        const result = await _dataProvider.launchUrl(code);
        if (!result) {
          return;
        }
        if (result?.cmd === 'joinStudyGroup') {
          console.log('join: ', groupId, result);
          if (groupId.toString() !== result.data) {
            alert(i18n.t('Chatroom.joinSuccess'));
            //reload the page that will show the new group
            window.location.reload();
          }
        }
      } else {
        window.open(url);
      }
    } catch (error) {
      alert(error);
    }
  };

  const searchAction = () => {
    const newSearch = prompt(i18n.t('Chatroom.SearchPrompt'), searchText.current);
    if (!newSearch || newSearch.trim().length === 0) {
      return;
    }
    searchText.current = newSearch;
    _dataProvider.getGroupMessage(groupId, `search=${encodeURIComponent(newSearch)}`).then((groupMessages) => {
      if (!groupMessages.length) {
        alert(i18n.t('Chatroom.NoResult'));
        return;
      }
      setScrollFromId(-1);
      setSearchState({ inSearch: true, searchedMessages: mapMessage(groupMessages) });
    });

    //gtSocket.getMessagesAsync({ search: text });
  };

  const renderActionIcon = () => {
    const { inSearch } = searchState;
    return (
      <img
        src={actionIcon}
        title={i18n.t('Chatroom.search')}
        style={{ backgroundColor: inSearch ? 'goldenrod' : 'Window' }}
      />
    );
  };

  const onPressAvatar = (user: any) => {
    user.tag = _dataProvider.getMyTagForUser(user.id);
    user.block = _dataProvider.getMyBlockForUser(user.id);
    console.log('Avatar: ', user);
    setShowMessageSender(user);
  };

  return (
    <div
      style={{
        marginTop: '10px',
        display: 'flex',
        border: '1px solid',
        height: chatHeight
      }}>
      <GiftedChat
        ref={giftedChatRef}
        text={searchState.inSearch ? i18n.t('Chatroom.inSearch') : inputText}
        messages={searchState.inSearch ? searchState.searchedMessages : messages}
        onSend={onSend}
        user={{
          id: getJwtObj().userId
        }}
        renderUsernameOnMessage={true}
        placeholder={i18n.t(searchState.inSearch ? 'Chatroom.inSearch' : 'Chatroom.inputPlaceHolder')}
        listViewProps={{
          onScroll: handleScroll
        }}
        onInputTextChanged={onInputTextChanged}
        onQuotePress={(msg: any) => alert(msg.quote)}
        messageActionSheet={messageActionSheet}
        onUrlPress={onUrlPress}
        onPress={onPress}
        onPressAvatar={onPressAvatar}
        onPressActionButton={searchAction}
        renderActionIcon={renderActionIcon}
        alwaysShowSend={true}
        label={
          <div style={{ whiteSpace: 'nowrap' }}>{i18n.t(searchState.inSearch ? 'Chatroom.all' : 'Chatroom.send')}</div>
        }
      />
      {showUserSelection && (
        <Dialog
          hidden={false}
          onDismiss={() => setShowUserSelection(false)}
          dialogContentProps={{
            showCloseButton: true
          }}>
          <div className='member-container'>
            {renderUserInSelection(theAllUserUser)}
            {users.map(renderUserInSelection)}
          </div>
        </Dialog>
      )}
      {showMessageSender && <UserProfileDialog user={showMessageSender} dismiss={setShowMessageSender} />}
    </div>
  );
};
